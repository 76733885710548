<template>
  <div>
    <v-row
      style="max-height: 70vh;"
      justify="center"
      v-if="veranstaltung.loader"
    >
      <v-col cols="10" align-self="center" class="text-center">
        <h2>Wettkampf wird geladen</h2>
        <v-progress-circular
          :size="50"
          :width="7"
          :color="template.colors.primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row
      v-if="!veranstaltung.loader && veranstaltung.data && disziplin"
      justify="center"
      class="py-5"
    >
      <v-col
        cols="12"
        :style="`color: ${template.colors.inline_primary_text};`"
        v-if="!veranstaltung.data.gastgeberverein"
      >
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn
              icon
              :to="`/wettkampf/${veranstaltung.id}/disziplinen`"
              exact
            >
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="text-center" align-self="center">
            <v-icon x-large>
              mdi-podium
            </v-icon>
          </v-col>
          <v-col>
            <h3 class="white--text font-weight-light">
              {{ veranstaltung.data.art }}
            </h3>
            <h2
              class="font-weight-bold"
              :style="`template: ${template.colors.inline_primary_text};`"
            >
              {{ veranstaltung.data.name }} - {{ disziplin.name }}
              {{
                disziplin.altersklasse
                  ? ' (' + disziplin.altersklasse + ')'
                  : ''
              }}
            </h2>
            <h4 v-if="disziplin">
              {{ parseDate(disziplin.beginn.datum) }} |
              {{ disziplin.beginn.uhrzeit }} - {{ disziplin.ende.uhrzeit }} Uhr
            </h4>
          </v-col>
        </v-row>
        <v-row class="pa-0 mt-4" justify="center" style="padding-bottom: 5vh;">
          <v-col
            cols="6"
            class="py-0"
            v-if="!veranstaltung.data.abgesagt && veranstaltung.data.valid"
          >
            <cardbutton
              :title="'ANWESENHEIT<br><br>'"
              :icon="'mdi-playlist-check'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${$route.params.veranstaltung}/disziplinen/${$route.params.disziplin}/anwesenheit`"
            />
          </v-col>
          <v-col
            cols="6"
            class="py-0"
            v-if="!veranstaltung.data.abgesagt && veranstaltung.data.valid"
          >
            <cardbutton
              :title="
                template.var.verband == 'DEU'
                  ? 'Gruppen<br>bearbeiten'
                  : 'Disziplinen<br>bearbeiten'
              "
              :icon="'mdi-cogs'"
              :textcolor="template.colors.block_text"
              :bgcolor="template.colors.blocks"
              upper
              twoline
              :to="`/wettkampf/${$route.params.veranstaltung}/disziplinen/${$route.params.disziplin}/bearbeiten`"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import router from '../../../../../routes/index'
import cardbutton from '../../../../../elements/cards/card-button'

export default {
  name: 'Disziplin',
  data() {
    return {
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      veranstaltungedit: false,
      editable: true,
      sportstaette: '',
      disziplin: '',
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.$route.params.veranstaltung)
      .collection('Disziplin')
      .doc(this.$route.params.disziplin)
      .get()
      .then((doc) => {
        this.disziplin = doc.data()
        this.disziplin.id = doc.id
      })
  },
  components: {
    cardbutton,
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    parseDate1(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.'
    },
    veranstaltung_absagen() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .update({
          abgesagt: true,
        })
        .then(() => {
          router.push('/start')
        })
    },
    veranstaltung_reaktivieren() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .update({
          abgesagt: false,
        })
    },
  },
}
</script>
